import React, { useEffect, useState } from 'react';
import { Box, Center, Text, VStack, Image, Avatar, Flex, Skeleton, SkeletonCircle, Tooltip } from '@chakra-ui/react';
import './DashboardSelector.css'
import Arrow from './Arrow.svg'
import Logo from './Logo.svg'
import { Link } from 'react-router-dom';

const DISCORD_API_VERSION = 10;
const DISCORD_API = `https://discord.com/api/v${DISCORD_API_VERSION}`

const DashboardSelector = () => {
  const [sessionData, setSessionData] = useState(null);
  const [guilds, setGuilds] = useState(null)

  useEffect(() => {
    const fetchSession = async () => {
      try {
        let response = await fetch('https://discord-forms.pages.dev/session', {
          credentials: 'include' // This is important to send cookies with the request
        });
        if (!response.ok) window.location.replace('https://discord.com/oauth2/authorize?client_id=942858850850205717&response_type=code&redirect_uri=https%3A%2F%2Fdiscord-forms.pages.dev%2Fredirect&scope=identify+guilds&prompt=none');

        response = await response.json();
        setSessionData(response);

        let guilds = await fetch(`${DISCORD_API}/users/@me/guilds`, {
          headers: {
            Authorization: `Bearer ${response.data.accessToken}`
          }
        });
        guilds = await guilds.json()
        setGuilds(guilds.filter(guild => ((guild.permissions & 32) === 32)).reverse())
      } catch (err) {
        window.location.replace('https://discord.com/oauth2/authorize?client_id=942858850850205717&response_type=code&redirect_uri=https%3A%2F%2Fdiscord-forms.pages.dev%2Fredirect&scope=identify+guilds&prompt=none');
      }
    };

    fetchSession();
  }, []);

  return (
    <Box background='#212831' className='fullscreen-height' color='white'>
      <Center className='fullscreen-height' flexDirection='column' gap={10}>
        <Flex alignItems='center' gap={2}>
          <Link to='/'><Avatar name='Forms' src={Logo} width='40px' height='40px' bg='oklab(0.321088 -0.000220731 -0.00934622)' className='logo' /></Link>
          <Text fontWeight='Bold' fontSize='24px'>Forms</Text>
        </Flex>
        <VStack gap='20px' background='rgba(255,255,255,.01)' padding='20px' width='calc(100% - 40px)' height='calc(100% - 150px)' maxWidth='500px' maxHeight='500px' boxShadow='0 0 20px #0000001a' border='1px solid rgba(255,255,255,.05)' borderRadius='10px'>
          {(sessionData && guilds) ? <>
            <Flex gap={3} alignItems='center' justifyContent='center' width='100%'>
              <Text color='#fffc'>Logged in as</Text>
              <Flex gap={2} alignItems='center'>
                <Image src={sessionData.data.userData.avatar ? `https://cdn.discordapp.com/avatars/${sessionData.data.userData.id}/${sessionData.data.userData.avatar}.png?size=32` : `https://cdn.discordapp.com/embed/avatars/${(sessionData.data.userData.id >> 22) % 6}.png`} borderRadius='50%' width='32px' bg='oklab(0.321088 -0.000220731 -0.00934622)' className='logo' />
                <Text>{sessionData.data.userData.username}</Text>
              </Flex>
              {/* <a href='https://discord.com/oauth2/authorize?client_id=942858850850205717&response_type=code&redirect_uri=https%3A%2F%2Fdiscord-forms.pages.dev%2Fredirect&scope=identify+guilds&prompt=consent'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 24 24"><path fill="#fffc" d="M9 12a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1Z" class=""></path><path fill="currentColor" fill-rule="evenodd" d="M2.75 3.02A3 3 0 0 1 5 2h10a3 3 0 0 1 3 3v7.64c0 .44-.55.7-.95.55a3 3 0 0 0-3.17 4.93l.02.03a.5.5 0 0 1-.35.85h-.05a.5.5 0 0 0-.5.5 2.5 2.5 0 0 1-3.68 2.2l-5.8-3.09A3 3 0 0 1 2 16V5a3 3 0 0 1 .76-1.98Zm1.3 1.95A.04.04 0 0 0 4 5v11c0 .36.2.68.49.86l5.77 3.08a.5.5 0 0 0 .74-.44V8.02a.5.5 0 0 0-.32-.46l-6.63-2.6Z" clip-rule="evenodd" class=""></path><path fill="currentColor" d="M15.3 16.7a1 1 0 0 1 1.4-1.4l4.3 4.29V16a1 1 0 1 1 2 0v6a1 1 0 0 1-1 1h-6a1 1 0 1 1 0-2h3.59l-4.3-4.3Z" class=""></path></svg></a> */}
              <Tooltip hasArrow label='Switch accounts' placement='top' bg='#0e0e0e' p={'4px 12px'} borderRadius='5px'>
                <a href='https://discord.com/login?redirect_to=%2Foauth2%2Fauthorize%3Fclient_id%3D942858850850205717%26response_type%3Dcode%26redirect_uri%3Dhttps%253A%252F%252Fdiscord-forms.pages.dev%252Fredirect%26scope%3Didentify%2Bguilds'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 24 24"><path fill="#fffc" d="M9 12a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1Z" class=""></path><path fill="currentColor" fill-rule="evenodd" d="M2.75 3.02A3 3 0 0 1 5 2h10a3 3 0 0 1 3 3v7.64c0 .44-.55.7-.95.55a3 3 0 0 0-3.17 4.93l.02.03a.5.5 0 0 1-.35.85h-.05a.5.5 0 0 0-.5.5 2.5 2.5 0 0 1-3.68 2.2l-5.8-3.09A3 3 0 0 1 2 16V5a3 3 0 0 1 .76-1.98Zm1.3 1.95A.04.04 0 0 0 4 5v11c0 .36.2.68.49.86l5.77 3.08a.5.5 0 0 0 .74-.44V8.02a.5.5 0 0 0-.32-.46l-6.63-2.6Z" clip-rule="evenodd" class=""></path><path fill="currentColor" d="M15.3 16.7a1 1 0 0 1 1.4-1.4l4.3 4.29V16a1 1 0 1 1 2 0v6a1 1 0 0 1-1 1h-6a1 1 0 1 1 0-2h3.59l-4.3-4.3Z" class=""></path></svg></a>
              </Tooltip>
            </Flex>
            <VStack align='flex-start' overflowY='auto' width='100%'>
              {guilds.map(guild => <Link className='guild-selector' to={`/dashboard/${guild.id}`}>
                <Avatar name={guild.name} src={`https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`} width='40px' height='40px' bg='oklab(0.321088 -0.000220731 -0.00934622)' />
                <Flex mx='10px' height='40px' width='100%' alignItems='center'><Text fontWeight='Bold'>{guild.name}</Text></Flex>
                <Image src={Arrow} width='12px' />
              </Link>)}
            </VStack>
          </> : <>
            <Flex width='100%' height='42px' alignItems='center' justifyContent='center'><Text color='#fffc'>Logging you in...</Text></Flex>
            <VStack width='100%' overflowY='auto'>{[0, 0, 0, 0, 0, 0, 0, 0].map(() => <Flex gap='10px' width='calc(100% - 10px)' alignItems='center' height='60px' padding='10px 20px' mx='5px'>
              <SkeletonCircle startColor='rgba(255,255,255,.03)' endColor='rgba(255,255,255,.12)' width='40px' height='40px' />
              <Skeleton startColor='rgba(255,255,255,.03)' endColor='rgba(255,255,255,.12)' height='40px' width='calc(100% - 40px)' borderRadius='4px' />
            </Flex>)}</VStack>
          </>}
        </VStack>
      </Center>
    </Box>
  );
};

export default DashboardSelector;
