import React from 'react';
import './Premium.css'
import { IoCheckmark, IoClose } from "react-icons/io5";
import { Box, Button, Flex } from '@chakra-ui/react';

function Premium({ guildConfig }) {
  return (
    <Box>
      <table id="feature-table">
        <caption style={{ fontWeight: 'Bold', fontSize: '22px', color: 'white' }}>Plan Comparison</caption>
        <thead>
          <tr>
            <td>Features</td>
            <td>Free</td>
            <td>Premium</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Custom Branding</td>
            <td><IoClose color='rgb(255, 107, 134)' /></td>
            <td><IoCheckmark color='rgb(39 186 38)' /></td>
          </tr>
          <tr>
            <td>Form Pages</td>
            <td>1</td>
            <td>5</td>
          </tr>
          <tr>
            <td>Webhook Submissions</td>
            <td><IoClose color='rgb(255, 107, 134)' /></td>
            <td><IoCheckmark color='rgb(39 186 38)' /></td>
          </tr>
          <tr>
            <td>Submission Cooldown</td>
            <td><IoClose color='rgb(255, 107, 134)' /></td>
            <td><IoCheckmark color='rgb(39 186 38)' /></td>
          </tr>
          <tr>
            <td>Require permissions to use submission buttons</td>
            <td><IoClose color='rgb(255, 107, 134)' /></td>
            <td><IoCheckmark color='rgb(39 186 38)' /></td>
          </tr>
          <tr>
            <td>Customise ticket channels</td>
            <td><IoClose color='rgb(255, 107, 134)' /></td>
            <td><IoCheckmark color='rgb(39 186 38)' /></td>
          </tr>
        </tbody>
      </table>
      <Flex justifyContent='center'>
        {!guildConfig.license && <a href='https://forms.lemonsqueezy.com/buy/6c238e6d-28f7-44ea-b965-b2f8c9e2512b'><Button mt='200px' margin='auto' background='#00A0DC' color='white' _hover={{ background: '#34B3E4'}}>Purchase Premium (€4.99/month)</Button></a>}
        {guildConfig.license && <Button mt='200px' margin='auto' background='#00A0DC' color='white' _hover={{ background: '#34B3E4'}} isDisabled={true}>This server has premium!</Button>}
      </Flex>
    </Box>
  )
}

export default Premium