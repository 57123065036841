import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Premium from './Premium';
import DashboardSelector from './DashboardSelector';
import Dashboard from './Dashboard';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <Router>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/premium" element={<Premium />} />
          <Route path="/dashboard" element={<DashboardSelector />} />
          <Route path="/dashboard/:guild_id" element={<Dashboard />} />
          <Route path="/dashboard/:guild_id/:section" element={<Dashboard />} />
          <Route path="/dashboard/:guild_id/:section/:sub_section" element={<Dashboard />} />
        </Routes>
      </Router>
    </ChakraProvider>
  </React.StrictMode>
);
